import React from 'react';
import PropTypes from 'prop-types';
import styles from './Container.module.css';

export default function Container({children}) {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>{children}</div>
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired
};
