import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Countdown from '../Countdown/Countdown';
import Icon from '../Icon/Icon';
import Competitor from '../Competitor/Competitor';
import styles from './Race.module.css';
import Odds from './Odds';

export default function Race({
  url,
  type,
  name,
  startTime,
  number,
  competitors = []
}) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Icon name={type} />
        <h3 className={styles.title}>{`R${number}  ${name}`}</h3>
        <div className={styles.timeContainer}>
          <Countdown time={startTime} />
          <span>{dayjs(startTime).tz().format('h:mma')}</span>
        </div>
      </div>
      <ul className={styles.competitors}>
        {competitors.map((competitor) => (
          <li key={competitor.programNumber}>
            <Competitor competitor={competitor} type={type} showTrainer>
            <Odds
                url={url}
                odd={competitor.odds}
              />
            </Competitor>
          </li>
        ))}
      </ul>
    </div>
  );
    
    
}

Race.propTypes = {
  url: PropTypes.string,
  number: PropTypes.number,
  startTime: PropTypes.string,
  competitors: PropTypes.arrayOf(PropTypes.shape()),
  id: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
};
