import PropTypes from 'prop-types';
import React from 'react';
import styles from './Competitor.module.css';
import ProgramNumber from '../ProgramNumber/ProgramNumber'

export default function Competitor({
  competitor,
  children,
  showTrainer = false,
  type
}) {

  const {programNumber, scratched, signature} = competitor
  const signatureUpdated = {
    textColor: competitor.signature.text.color,
    backgroundColors: competitor.signature.background.colors,
    backgroundType: competitor.signature.background.type
  }

  const jockeySymbol = (type === "Thoroughbred")? 'J: ' : 'D: '

  return (
    <div className={styles.competitor}>
      {signature && <ProgramNumber programNumber={programNumber} scratched={scratched}  signature={signatureUpdated} />} 
      <div className={styles.nameContainer}>
        <span
          className={styles.name}
        >{`${competitor.name}`}</span>
        <span className={styles.jockey} >
            {competitor.jockey ? `${jockeySymbol} ${competitor.jockey}` : null}
        </span>
        <span className={styles.jockey} >
          {showTrainer && competitor.trainer
              ? `T: ${competitor.trainer}`
              : null}
        </span>
      </div>
      {children}
    </div>
  );
}

Competitor.propTypes = {
  competitor: PropTypes.shape().isRequired,
  children: PropTypes.node,
  showTrainer: PropTypes.bool,
};
