import { useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

export function useRaceInfoSubscription(raceData, websocketUrl) {
  const [raceInfo, setRaceInfo] = useState([]); // Store an array of race data

  useEffect(() => {
    if (!raceData || raceData.length === 0 || !websocketUrl) return;

    const sock = new SockJS(websocketUrl);
    const stompClient = new Client({
      webSocketFactory: () => sock,
      reconnectDelay: 5000,
      debug: (str) => console.log(str),
    });

    stompClient.activate();

    stompClient.onConnect = () => {
      console.log('Connected to WebSocket');
      const subscriptions = raceData.map(({ raceId, type, name, raceNumber, postTime, status }) => {
        
        const topicName = `/broker/v2.race.${raceId}.en-US.runners`;
        console.log(`Subscribing to topic: ${topicName}`);
        
        return stompClient.subscribe(topicName, (message) => {
          try {
            const parsedMessage = JSON.parse(message.body);

            const competitors = parsedMessage.runners.map((runner) => ({
              name: runner.horse.horseName,
              jockey: runner.riderName,
              trainer: runner.horse.trainerName,
              postPosition: runner.postPosition,
              programNumber: runner.programNumber,
              signature: runner.signature,
              scratch: runner.scratch,
              odds: runner.odds.current
            }));
            // Update the raceInfo state with raceId, type, and competitors
            setRaceInfo((prevRaceInfo) => {
              // Check if raceId already exists in the state
              const existingRaceIndex = prevRaceInfo.findIndex((race) => race.raceId === raceId);

              if (existingRaceIndex !== -1) {
                // Update the existing race entry
                const updatedRaceInfo = [...prevRaceInfo];
                updatedRaceInfo[existingRaceIndex] = { raceId, type, competitors, name, raceNumber, postTime, status };
                return updatedRaceInfo;
              }

              // Add a new entry if raceId does not exist
              return [...prevRaceInfo,{ raceId, type, competitors, name, raceNumber, postTime, status }];
            });
          } catch (error) {
            console.error(`Failed to parse race info for race ${raceId}:`, error);
          }
        });
      });

      // Cleanup function to unsubscribe from topics when component unmounts
      return () => {
        subscriptions.forEach((subscription) => subscription.unsubscribe());
        stompClient.deactivate(); // Deactivate the WebSocket client
      };
    };

    sock.onclose = () => {
      console.log('WebSocket connection closed');
    };
  }, [raceData, websocketUrl]); // Re-run effect when raceData or websocketUrl changes

  return raceInfo;
}
