import { useMemo } from 'react';
import { useRaceInfoSubscription } from '../hooks/useRaceInfoSubscription';

export function useRacesInfo(meetings, websocketUrl) {
  // Extract race data (raceId and type) for the first 3 meetings
  const raceData = useMemo(() => 
    meetings.map((meeting) => {
    return ({
        raceId: meeting.raceCards[0]?.nextRace?.id,
        type: meeting.type,
        name: meeting.raceCards[0]?.name,
        raceNumber: meeting.raceCards[0]?.nextRace.number,
        postTime: meeting.raceCards[0]?.nextRace?.postTime,
        status: meeting.raceCards[0]?.nextRace?.status,
      }); 
    }).filter(race => race.raceId), // Filter out undefined raceIds
    [meetings]
  );

  // Use raceInfoSubscription to get details for these races
  const racesInfo = useRaceInfoSubscription(raceData, websocketUrl);

  // Combine raceInfo with other details from meetings
  const racesDetails = useMemo(() =>
    meetings.map((meeting) => {
      const raceId = meeting.raceCards[0]?.nextRace?.id;
      const races = racesInfo.find(race => race.raceId === raceId)
      return races;
    }),
    [meetings, racesInfo]
  );

  return racesDetails;
}
