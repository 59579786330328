import PropTypes from 'prop-types';
import React from 'react';
import styles from './Odds.module.css';

export default function Odds({ url, odd }) {
  return (
    <a  href={url} className={styles.oddsButton} > { odd || '-'}
    </a>
  );
}

Odds.propTypes = {
  url: PropTypes.string,
  odd: PropTypes.string
};
