import PropTypes from 'prop-types';
import React from 'react';
import Container from '../Container/Container';
import Races from './Races';
import {useNext3MeetingsContext} from '../../contexts/useNext3MeetingsContext';


export default function NextToJump({url}) {
  const next3Races = useNext3MeetingsContext();
  return next3Races.length > 0 ? (
    <Container>
      <Races url= {url} races={next3Races.map((race) => ({...race}))} />
    </Container>
  ) : null;
}

NextToJump.propTypes = {
  websocketUrl: PropTypes.string,
  url: PropTypes.string,
};
