import { useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

export default function useNext3Meetings(websocketUrl) {
  const [next3Meetings, setNext3Meetings] = useState([]);

  useEffect(() => {
    if (!websocketUrl) {
      console.error('WebSocket URL is not provided');
      return;
    }

    const sock = new SockJS(websocketUrl);
    const stompClient = new Client({
      webSocketFactory: () => sock,
      reconnectDelay: 5000,
      debug: (str) => console.log(str),
    });
    stompClient.activate();

    stompClient.onConnect = (frame) => {
      const topicName = '/broker/v2.tracks.en-US';
      stompClient.subscribe(topicName, (message) => {
        try {
          const parsedMessage = JSON.parse(message.body);

          const sortedRaces = parsedMessage
            .map((meeting) => ({
              ...meeting,
              raceCards: meeting.raceCards.map((raceCard) => ({
                ...raceCard,
                nextRace: {
                  ...raceCard.nextRace,
                  postTime: new Date(raceCard.nextRace.postTime), // Convert to Date for sorting
                },
              })),
            }))
            .sort((a, b) => {
              const postTimeA = a.raceCards[0]?.nextRace?.postTime;
              const postTimeB = b.raceCards[0]?.nextRace?.postTime;
              return postTimeA - postTimeB; 
            });

          // Set the initial meetings data
          setNext3Meetings(sortedRaces.slice(0, 3));

        } catch (error) {
          console.error('Failed to parse meetings message:', error);
        }
      });
    };

    sock.onopen = function () {
      console.log('WebSocket connection opened');
    };

    sock.onclose = function () {
      console.log('WebSocket connection closed');
    };

    return () => {
      stompClient.deactivate();
    };
  }, [websocketUrl]); 

  return next3Meetings;
}
