import PropTypes from 'prop-types';
import React from 'react';
import Race from './Race';
import styles from './Races.module.css';

export default function Races({ races = [], url }) {
  return (
    <div className={styles.racesContainer}>
      {races.map((race, index) => {
        return (
          <Race 
            key={race.raceId || index}  
            url={url} 
            name={race.name || ''} 
            id={race.raceId} 
            type={race.type || "Thoroughbred"} 
            postTime={race.postTime || ''} 
            number={race.raceNumber || 0} 
            competitors={race.competitors || []} />
        )
      })}
    </div>
  );
}

Races.propTypes = {
  races: PropTypes.arrayOf(PropTypes.shape()),
};
