import React, { createContext, useContext } from 'react';
import useNext3Meetings from '../hooks/useNext3Meetings';
import { useRacesInfo } from '../hooks/useRacesInfo';

const MeetingsContext = createContext({});

export const Next3MeetingsProvider = ({ websocketUrl, children }) => {
  const meetings = useNext3Meetings(websocketUrl);

  // Use the custom hook for race information subscriptions
  const racesDetails = useRacesInfo(meetings, websocketUrl);
  return (
    <MeetingsContext.Provider value={racesDetails}>
      {children}
    </MeetingsContext.Provider>
  );
};

export const useNext3MeetingsContext = () => useContext(MeetingsContext);
