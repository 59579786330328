import React from 'react';
import NextToJump from './components/Next3Races/NextToJump';
import { Next3MeetingsProvider } from './contexts/useNext3MeetingsContext';
import './App.css';
import './styles/variables.css';
import './styles/mediaqueries.css';
import './styles/globals.css';

function App() {
  // Access the settings from the localized script or use default values
  const {
    url = 'https://wager.suffolkotb.com/wager',
    websocketUrl = 'https://gt-qc.testbetmservices.com/api/falcon/ws/'
  } = window.horseRacingEventsSettings || {};


  return (
    <Next3MeetingsProvider websocketUrl={websocketUrl}>
      <div className="App">
        <NextToJump url={url} websocketUrl={websocketUrl} />
      </div>
    </Next3MeetingsProvider>
  );
}

export default App;
